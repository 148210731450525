import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      //<header>
      //  <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
      //    <NavbarBrand tag={Link} to="/">cleancoach_app</NavbarBrand>
      //    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
      //    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
      //      <ul className="navbar-nav flex-grow">
      //        <NavItem>
      //          <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
      //        </NavItem>
      //        <NavItem>
      //          <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
      //        </NavItem>
      //        <NavItem>
      //          <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
      //        </NavItem>
      //      </ul>
      //    </Collapse>
      //  </Navbar>
      //</header>
       <div></div>
      //<header id="header" class="header fixed-top d-flex align-items-center">
      //    <div class="container d-flex align-items-center justify-content-between">

      //        <a href="index.html" class="logo d-flex align-items-center me-auto me-lg-0">
      //            <img src="assets/img/logo.png" alt=""/>
      //              <h1>clean<span>sofa</span></h1>
      //        </a>
      //        <nav id="navbar" class="navbar">
      //            <ul>
      //                  <li><a href="#hero">Acasă</a></li>
      //                  <li><a href="#gallery">Lucrări</a></li>
      //                <li><a href="#contact">Contact</a></li>
      //            </ul>
      //        </nav>

      //          <a class="btn-book-a-table" href="#book-a-table">Rezervă</a>
      //        <i class="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
      //        <i class="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>

      //    </div>
      //</header>
    );
  }
}
